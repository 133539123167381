<template>
  <b-modal
    id="goal-modal"
    ref="goal-modal"
    :title="$t('GOAL')"
    :ok-title="goal.id ? $t('BUTTON.UPDATE') : $t('BUTTON.ADD')"
    cancel-variant="outline-secondary"
    @show="resetModal"
    @hidden="resetModal"
    @ok="handleOk"
  >
    <validation-observer ref="validationRules">
      <b-form
        ref="form"
        @submit.stop.prevent="handleSubmit"
      >
        <b-row>
          <!-- Description -->
          <b-col cols="12">
            <b-form-group
              :label="$t('DESCRIPTION')"
              label-for="id-description"
              :invalid-feedback="$t('DESCRIPTION_REQUIRED')"
            >
              <validation-provider
                #default="{ errors }"
                :name="$t('DESCRIPTION')"
                rules="required"
              >
                <b-form-input
                  id="id-description"
                  v-model="goal.description"
                  maxlength="120"
                  :placeholder="$t('DESCRIPTION')"
                  required
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <!-- Task -->
          <b-col cols="12">
            <b-form-group
              :label="$t('TASK')"
              label-for="id-task"
            >
              <validation-provider
                #default="{ errors }"
                :name="$t('TASK')"
                rules="required"
              >
                <v-select
                  id="id-task"
                  v-model="goal.taskId"
                  :placeholder="$t('SELECT_TASK')"
                  label="name"
                  :options="allContentTasks"
                  :clearable="false"
                  :reduce="type => type.id"
                  @input="onTaskSelect"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <!-- Sub Task -->
          <b-col cols="12">
            <b-form-group
              :label="$t('SUBTASK')"
              label-for="id-task"
            >
              <validation-provider
                #default="{ errors }"
                :name="$t('SUBTASK')"
                rules="required"
              >
                <v-select
                  id="id-task"
                  v-model="goal.subtaskId"
                  :placeholder="$t('SELECT_SUB_TASK')"
                  label="name"
                  :options="filteredSubtasks"
                  :clearable="false"
                  :reduce="type => type.id"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <!-- Goal Type -->
          <b-col cols="6">
            <b-form-group
              :label="$t('GOAL_TYPE')"
              label-for="id-goalType"
            >
              <validation-provider
                #default="{ errors }"
                :name="$t('GOAL_TYPE')"
                rules="required"
              >
                <v-select
                  id="id-goalType"
                  v-model="goal.goalTypeId"
                  :placeholder="$t('SELECT_GOAL_TYPE')"
                  label="name"
                  :options="allGoalTypes"
                  :clearable="false"
                  :reduce="type => type.id"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <!-- Period -->
          <b-col
            cols="6"
          >
            <b-form-group
              :label="$t('PERIOD')"
              label-for="id-period"
            >
              <validation-provider
                #default="{ errors }"
                :name="$t('PERIOD')"
                rules="required"
              >
                <v-select
                  id="id-period"
                  v-model="goal.goalPeriodId"
                  :placeholder="$t('SELECT_GOAL_PERIOD')"
                  label="name"
                  :options="allGoalPeriods"
                  :clearable="false"
                  :reduce="type => type.id"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <!-- Subject -->
          <b-col
            cols="6"
          >
            <b-form-group
              :label="$t('SUBJECT')"
              label-for="id-subject"
            >
              <validation-provider
                #default="{ errors }"
                :name="$t('SUBJECT')"
                rules="required"
              >
                <v-select
                  id="id-subject"
                  v-model="goal.goalSubjectId"
                  label="name"
                  :placeholder="$t('SELECT_GOAL_SUBJECT')"
                  :options="allGoalSubjects"
                  :clearable="false"
                  :reduce="type => type.id"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <!-- Value -->
          <b-col cols="6">
            <b-form-group
              :label="$t('VALUE')"
              label-for="id-value"
            >
              <validation-provider
                #default="{ errors }"
                :name="$t('VALUE')"
                rules="required"
              >
                <b-form-input
                  id="id-value"
                  v-model="goal.value"
                  type="number"
                  :placeholder="$t('VALUE')"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <!-- Enable Goal -->
          <b-col cols="12">
            <b-form-group>
              <b-form-checkbox
                id="checkbox-goal-enabled"
                v-model="goal.enabled"
                name="checkbox-goal-enabled"
              >
                {{ $t('GOAL_ENABLE_PROMPT') }}
              </b-form-checkbox>
            </b-form-group>
          </b-col>

        </b-row>
      </b-form>
    </validation-observer>
  </b-modal>
</template>
<script>
import {
  BForm, BFormGroup, BFormInput, BModal,
  VBModal, BRow, BCol, BFormCheckbox,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import { required } from '@validations'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import mixinAlert from '@/constants/mixinAlert'
import { mapActions } from 'vuex'

export default {
  components: {
    BForm,
    BFormGroup,
    BFormInput,
    BModal,
    BRow,
    BCol,
    vSelect,
    BFormCheckbox,
    ValidationProvider,
    // eslint-disable-next-line vue/no-unused-components
    ValidationObserver,
  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  model: {
    prop: 'editGoal',
  },
  props: {
    content: {
      type: Object,
      required: true,
    },
    editGoal: {
      type: Object,
      default: () => {},
    },
    allContentTasks: {
      type: Array,
      default: () => [],
    },
    allGoalSubjects: {
      type: Array,
      default: () => [],
    },
    allGoalPeriods: {
      type: Array,
      default: () => [],
    },
    allGoalTypes: {
      type: Array,
      default: () => [],
    },
    fetchGoals: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      goal: {
        id: 0,
        contentId: 0,
        taskId: '',
        subtaskId: '',
        description: '',
        goalTypeId: '',
        goalPeriodId: '',
        goalSubjectId: '',
        value: 0,
        enabled: false,
      },
      filteredSubtasks: [],
      required,

    }
  },
  watch: {
    editGoal: {
      handler() {
        if (this.editGoal) {
          this.goal = this.editGoal
        }
      },
    },
    'goal.taskId': {
      handler(newTaskId) {
        this.updateFilteredSubtasks(newTaskId)
      },
      immediate: true,
    },
  },
  setup() {
    const {
      successMessage,
      showErrorMessage,
    } = mixinAlert()

    return {
      successMessage,
      showErrorMessage,
    }
  },
  methods: {
    ...mapActions('goal', [
      'createGoal',
      'updateGoal']),
    handleOk(bvModalEvt) {
      // Prevent modal from closing
      bvModalEvt.preventDefault()
      // Trigger submit handler
      this.handleSubmit()
    },
    resetModal() {
      this.goal = {
        id: 0,
        contentId: 0,
        taskId: '',
        subtaskId: '',
        description: '',
        goalTypeId: '',
        goalPeriodId: '',
        goalSubjectId: '',
        value: 0,
        enabled: false,
      }
    },
    onTaskSelect(taskId) {
      this.updateFilteredSubtasks(taskId)
    },
    updateFilteredSubtasks(taskId) {
      const selectedTask = this.allContentTasks.find(task => task.id === taskId)
      this.filteredSubtasks = selectedTask ? selectedTask.subtasks : []

      // Ensure subtaskId is valid and reset if it's no longer applicable
      if (!this.filteredSubtasks.some(subtask => subtask.id === this.goal.subtaskId)) {
        this.goal.subtaskId = null
      }
    },
    handleSubmit() {
      this.$refs.validationRules.validate().then(success => {
        if (success) {
          const payload = {
            ...this.goal, contentId: this.content.id,
          }
          const saveOperation = this.goal.id ? this.updateGoal : this.createGoal

          saveOperation(payload)
            .then(response => {
              if (response) {
                const messageKey = this.goal.id ? 'MESSAGE.GOAL_UPDATED' : 'MESSAGE.GOAL_CREATED'
                this.successMessage(this.$i18n.t(messageKey))
                this.resetModal()
                this.fetchGoals(this.content.id)
                this.$bvModal.hide('goal-modal')
              }
            })
            .catch(() => {
              this.showErrorMessage()
            })
        }
      })
    },

  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
@import '@core/scss/vue/libs/quill.scss';
</style>

<style lang="scss" scoped>
@import '~@core/scss/base/bootstrap-extended/include';

.assignee-selector {
  ::v-deep .vs__dropdown-toggle {
  padding-left: 0;
  }
}

#quil-content ::v-deep {
  > .ql-container {
    border-bottom: 0;
  }

  + #quill-toolbar {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border-bottom-left-radius: $border-radius;
    border-bottom-right-radius: $border-radius;
  }
}
</style>
